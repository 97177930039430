import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

/* SBT DEPLOYER ADDRESS */
export const SBT_DEPLOYER_ADDRESSES : AddressMap = {
  // [SupportedChainId.GOERLI]: "0x72D5020993c43ffdFCe2aEdd0442b776A9617097",
  // [SupportedChainId.POLYGON]: '0x022e58834d2c91Ed9C06E977B6e8aaDf019b514D',
  [SupportedChainId.GOERLI]: '0x0C0E11bedca82D1EC3d748F01bf962A37a004652',
  [SupportedChainId.POLYGON]: '0xEd8bf520A0667349BF941110FAE2eAb478E38bcE',
} 

/* SBT DEPLOYER ADDRESS V0 */
export const SBT_DEPLOYER_ADDRESSES_V0 : AddressMap = {
  [SupportedChainId.GOERLI]: '0xC1eC8454a75A82090B1831b055883e914Dc43CBb',
  [SupportedChainId.POLYGON]: '0x022e58834d2c91Ed9C06E977B6e8aaDf019b514D',
} 

export const SBT_DEPLOYER_ADDRESSES_V3 : AddressMap = {
  [SupportedChainId.GOERLI]: '0x0C0E11bedca82D1EC3d748F01bf962A37a004652',
  [SupportedChainId.POLYGON]: '0xEd8bf520A0667349BF941110FAE2eAb478E38bcE',
} 

export const BICONOMY_FORWARDER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x84a0856b038eaAd1cC7E297cF34A7e72685A8693',
  [SupportedChainId.GOERLI]: '0xE041608922d06a4F26C0d4c27d8bCD01daf1f792',
  [SupportedChainId.POLYGON]: '0xf0511f123164602042ab2bCF02111fA5D3Fe97CD',
  [SupportedChainId.CELO]: '0xd65dDcdB93e794AE315742FC627210ce4625F708',
  [SupportedChainId.BASE]:'0x84a0856b038eaAd1cC7E297cF34A7e72685A8693',
  [SupportedChainId.GNOSIS]:'0x86C80a8aa58e0A4fa09A69624c31Ab2a6CAD56b8',
  [SupportedChainId.OPTIMISM]:'0xefba8a2a82ec1fb1273806174f5e28fbb917cf95',
  [SupportedChainId.ARBITRUM]:'0xfe0fa3C06d03bDC7fb49c892BbB39113B534fB57',
  [SupportedChainId.AVALANCHE]:'0x64CD353384109423a966dCd3Aa30D884C9b2E057',
}

export const BICONOMY_GAS_TANK_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x6781dbfdbd6a2803e1698c6e705659d3b597f643',
  [SupportedChainId.GOERLI]: '0x6781dbfdbd6a2803e1698c6e705659d3b597f643',
  [SupportedChainId.POLYGON]: '0xeb808ba857a080d35554fe5830dc61df1ba53e0c',
  [SupportedChainId.CELO]: '0xEb394FE49C745D29564B3cC362530031FC741b01',
  [SupportedChainId.BASE]:'0xeb808ba857a080d35554fe5830dc61df1ba53e0c',
  [SupportedChainId.GNOSIS]: '0xe635A772A5907Bc655B249f09BF0AC539dc0bA75',
  [SupportedChainId.OPTIMISM]:'0x5Bc7c8cFC6d54c055547Fcd8f6Ee4f2eDcda613e',
  [SupportedChainId.ARBITRUM]: '0xCf602dC6732fDFf1010Cefb32C3DA9a10Ec678F8',
  [SupportedChainId.AVALANCHE]: '0xe635A772A5907Bc655B249f09BF0AC539dc0bA75',
}
