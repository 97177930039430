export const colors = [
    {
      backgroudColor: "#E64460",
      left: "40.42%",
      right: "55.44%",
      top: "37.33%",
      bottom: "60.48%",
      transform: "rotate(-130.39deg)",
    },
    {
      backgroudColor: "#188C7C",
      left: "70.42%",
      right: "25.44%",
      top: "41.91%",
      bottom: "55.9%",
      transform: "rotate(135.12deg)",
    },
    {
      backgroudColor: "#CF2E21",
      left: "21.42%",
      right: "25.44%",
      top: "41.91%",
      bottom: "55.9%",
      transform: "rotate(-49.14deg)",
    },
    {
      backgroudColor: "#188C7C",
      left: "22.44%",
      right: "73.41%",
      top: "62.87%",
      bottom: "44.94%",
      transform: "rotate(35.81deg)",
    },
    {
      backgroudColor: "#FF8200",
      left: "10.1%",
      right: "85.76%",
      top: "68.61%",
      bottom: "29.2%",
      transform: "rotate(35.81deg)",
    },
    {
      backgroudColor: "#1A395F",
      left: "80.27%",
      right: "15.59%",
      top: "34.57%",
      bottom: "63.24%",
      transform: "rotate(-174.19deg",
    },
    {
      backgroudColor: "#E64460",
      left: "75.6%",
      right: "20.26%",
      top: "54.05%",
      bottom: "43.76%",
      transform: "rotate(138.33deg)",
    },
    {
      backgroudColor: "#751C48",
      left: "90.1%",
      right: "9.9%",
      top: "70.4%",
      bottom: "29.6%",
      transform: "rotate(100.86deg)",
    },
  ];
  